<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#005f32"
      spinner="bar-fade-scale"
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap class="pa-2">
      <v-flex xs10 text-left pa-4>
        <span class="itemHeading">ASSIGN</span>
      </v-flex>

      <v-flex xs2 text-left pa-4>
        <v-text-field
    v-model="keyword"
    outlined
    class="rounded-xl"
    dense
    hide-details
    clearable
    label="Search Employee"
  >
  </v-text-field>
      </v-flex>
      <v-flex xs12 pa-4>
        <v-card color="#F5F5DC" tile>
          <v-layout wrap pa-2 justify-start v-if="data">
            <v-flex xs12 sm8 md9 align-self-start text-left pa-1>
              <v-layout wrap>
                <v-flex xs12 align-self-center text-left pa-1>
                  <span
                    style="font-size: 18px"
                    class="kumbhMedium"
                    v-if="data.name"
                  >
                    Name : {{ data.name }}
                  </span>
                  <span style="font-size: 18px" class="kumbhMedium" v-else>
                    NA
                  </span>
                </v-flex>
                <v-flex xs9 align-self-center text-left pa-1>
                  <span
                    style="font-size: 18px"
                    class="kumbhMedium"
                    v-if="data.employeeId"
                  >
                    Employee Id : {{ data.employeeId }}
                  </span>
                  <span style="font-size: 18px" class="kumbhMedium" v-else>
                    NA
                  </span>
                </v-flex>
                <v-flex xs9 align-self-center text-left pa-1>
                  <span
                    style="font-size: 18px"
                    class="kumbhMedium"
                    v-if="data.employeeStatus"
                  >
                    Emp. Status : {{ data.employeeStatus }}
                  </span>
                  <span style="font-size: 22px" class="kumbhMedium" v-else>
                    NA
                  </span>
                </v-flex>
                <v-flex xs12 sm6 align-self-center text-left pa-1>
                  <span
                    style="font-size: 18px"
                    class="kumbhMedium"
                    v-if="data.dateofjoining"
                  >
                    Date of joining :
                    {{ data.dateofjoining.slice(0, 10) }}
                  </span>
                  <span style="font-size: 18px" class="kumbhMedium" v-else>
                    Date of joining : NA
                  </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>

      <v-flex pa-6 xs4 v-for="(item, i) in list" :key="i">
        <v-card>
          <v-layout wrap>
            <v-flex pa-2 xs12 text-left>
              <span class="kumbhMedium">Name :</span
              ><span>{{ item.name }}</span>
            </v-flex>
            <v-flex pl-2 pt-1 xs12 text-left>
              <span class="kumbhMedium">Email :</span
              ><span>{{ item.email }}</span>
            </v-flex>

            <v-flex pl-2 pt-1 xs12 text-left>
              <span class="kumbhMedium">Employee Id :</span
              ><span>{{ item.employeeId }}</span>
            </v-flex>

            <v-flex pl-2 pt-1 xs12 text-left>
              <span class="kumbhMedium">Position :</span
              ><span> {{ item.positionid[0].name }}</span>
            </v-flex>
            <v-flex xs12 pa-2>
              <center>
                <v-btn
                  @click="(acceptdialog = true), (curid = item._id)"
                  style="font-family: kumbhBold"
                  dark
                  class="rounded-xl"
                  color="#005f32"
                >
                  ASSIGN
                </v-btn>
              </center>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-dialog v-model="acceptdialog" max-width="600px">
        <v-card>
          <v-card-title
            ><span class="opensanssemibold"
              >Are you sure you want to Assign?</span
            ></v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="acceptdialog = false"
              >Cancel</v-btn
            >
            <v-btn color="blue darken-1" text @click="assign()">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-layout wrap>
        <v-flex xs12>
          <v-pagination
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :length="pages"
            :total-visible="7"
            v-model="currentpage"
            color="green"
          ></v-pagination>
        </v-flex>
      </v-layout>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      list: [],
      page: 1,
keyword: null,
      curid: [],
      data: [],
      array: [],
      currentpage: 1,
      msg: null,
      limit: 12,
      showsnackbar: false,
      acceptdialog: false,
      pages: 0,
      appLoading: false,
    };
  },
  watch: {
    currentpage() {
      this.getlist();
    },
    keyword() {
this.getlist();
},
  },
  mounted() {
    this.getlist();
  },

  beforeMount() {
    this.getData();
  },

  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/employee/details/view",
        method: "GET",
        params: {
          id: this.$route.query.id,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.data = response.data.data;
          this.employeeId = response.data.data._id;
          this.projects = response.data.projects;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    assign() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/coEmployee/assign",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          employeeId: this.curid,
          toEmployeeId: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            (this.acceptdialog = false), this.getlist();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getlist() {
     
      axios({
        method: "GET",
        url: "/employee/CoEmployeeAssign/list",
        params: {
          empId: this.$route.query.id,
          page: this.currentpage,
          limit: this.limit,
          keyword: this.keyword,

        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.array = response.data.data.positionid;
          this.pages = Math.ceil(response.data.totalLength / this.limit);
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
  